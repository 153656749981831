import Button from 'aac-components/components/Button';
import Image from 'next/image';
import {BREAKPOINT} from '../../lib/styles';
import {useContext} from 'react';
import AppContext from '../AppContext';
import styled from 'styled-components';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import GeoTargetMapWrapper from '../GeoTargetMapWrapper';
import FacilityCardSlider from '../FacilityCardSlider';

const MapSection = () => {
    const title = 'Our Locations Nationwide';
    const copy =
        'American Addiction Centers provides addiction treatment and mental health services at multiple locations nationwide.';

    const {isMobile} = useContext(AppContext);
    return (
        <section className="section-map">
            <div className="section-map__inner">
                <div className="section-map__content">
                    <div className="section-map__content--left">
                        <div className="section-map__logo">
                            <Image
                                src="https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/aac-lockup-horizontal-color-v2.svg"
                                fill
                                style={{objectFit: 'contain'}}
                            />
                        </div>
                        <h2
                            className="section-map__title"
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                        {isMobile && (
                            <div className="section-map__content--image">
                                <GeoTargetMapWrapper />
                            </div>
                        )}
                        <div
                            className="section-map__copy"
                            dangerouslySetInnerHTML={{__html: copy}}
                        />
                        <div className="section-map__button">
                            <Button
                                href="/treatment-centers"
                                theme="invert"
                                style={{minWidth: '100%', fontWeight: 'normal'}}>
                                View Our Locations
                            </Button>
                        </div>
                    </div>
                    {!isMobile && (
                        <div className="section-map__content--image">
                            <GeoTargetMapWrapper />
                        </div>
                    )}
                </div>
                <div className="section-map__facilities">
                    <FacilityCardSliderStyles>
                        <FacilityCardSlider showViewAllButton={false} />
                    </FacilityCardSliderStyles>
                </div>
            </div>
            <style jsx>{`
                .section-map {
                    background: rgba(252, 248, 245, 0.8);
                    padding: 64px 15px;
                    text-align: center;
                    margin-bottom: 64px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map {
                        padding: 80px 0;
                        text-align: left;
                        margin-bottom: 120px;
                    }
                }
                .section-map__inner {
                    max-width: ${MAX_WIDTH_PX};
                    margin: 0 auto;
                }
                .section-map__logo {
                    width: 250px;
                    height: 40px;
                    margin: 0 auto 24px auto;
                    position: relative;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__logo {
                        width: 250px;
                        height: 40px;
                        margin: 0 0 32px 0;
                        position: relative;
                    }
                }
                .section-map__title {
                    margin-bottom: 24px;
                }
                .section-map__copy {
                    margin-bottom: 24px;
                    line-height: 24px;
                }
                .section-map__button {
                    max-width: 100%;
                    margin-bottom: 40px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__button {
                        max-width: fit-content;
                    }
                }
                .section-map__content {
                    display: grid;
                    grid-template-columns: 1fr;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__content {
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 80px;
                        margin-bottom: 40px;
                        align-items: center;
                    }
                }
                .section-map__content--image {
                    position: relative;
                    min-width: 100%;
                    height: 200px;
                    margin-bottom: 60px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__content--image {
                        height: 350px;
                        margin-bottom: 16px;
                    }
                }
            `}</style>
        </section>
    );
};
export default MapSection;

const FacilityCardSliderStyles = styled.div`
    /* Track */
    .facility-card-slider::-webkit-scrollbar-track {
        background: var(--gray-200);

        border-radius: 2em;
    }

    /* Handle */
    .facility-card-slider::-webkit-scrollbar-thumb {
        background: var(--interactive-200);
        border-radius: 2em;
    }
`;
